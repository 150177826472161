import { EmailRequest, EmailResponse} from '../proto/qis/email/v1/email_api_pb';
import { EmailServicePromiseClient } from '../proto/qis/email/v1/email_api_grpc_web_pb';
import { AuthorizationInterceptor } from './interceptors/authorization_interceptor';

export default class {
    constructor (private emailService: EmailServicePromiseClient = new EmailServicePromiseClient(process.env.VUE_APP_GRPC_URL, null, {'unaryInterceptors': [new AuthorizationInterceptor()]})) {
    }

    async sendEmail(data: any) {
        const request = new EmailRequest();
        request.setDestination('bruno@bruno.adm.br');
        request.setSubject('Contato iNovaQuant');
        request.setContent(data.name +"\n" + data.email + "\n" + data.cellphone + "\n" + data.message);
        request.setToken(data.token);
        try {
            return await this.emailService.sendEmail(request, {});
        } catch (err) {
            throw err
        }
    }
}