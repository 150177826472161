<template>
    <header class="header-global">
        <base-nav class="navbar-main fixed bg-darker" transparent type="" effect="light" expand>
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
                <img src="img/brand/white.png" alt="logo">
            </router-link>

            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-6 collapse-brand">
                    <a href="#">
                        <img src="img/brand/black.png">
                    </a>
                </div>
                <div class="col-6 collapse-close">
                    <close-button @click="closeMenu"></close-button>
                </div>
            </div>

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
                <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl" v-if="!logged">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="ni ni-ui-04 d-lg-none"></i>
                        <span class="nav-link-inner--text">Assinaturas</span>
                    </a>
                    <div class="dropdown-menu-inner">
                        <router-link to="#"
                           class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-default rounded-circle text-white">
                                <i class="ni ni-spaceship"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h6 class="heading text-default mb-md-1">Precificação de Derivativos</h6>
                                <p class="description d-none d-md-inline-block mb-0">Opções exóticas, estruturas, NDF, SWAP</p>
                            </div>
                        </router-link>
                        <router-link to="#"
                           class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                <i class="ni ni-ui-04"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-warning mb-md-1">Positions</h5>
                                <p class="description d-none d-md-inline-block mb-0">Controle e precificação de posições em realtime</p>
                            </div>
                        </router-link>
                    </div>
                </base-dropdown>
                <base-dropdown tag="li" class="nav-item">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="ni ni-collection d-lg-none"></i>
                        <span class="nav-link-inner--text">Menu</span>
                    </a>
                    <router-link to="/profile" class="dropdown-item" v-if="logged">Profile</router-link>
                    <router-link to="/register" class="dropdown-item" v-if="!logged">Register</router-link>
                </base-dropdown>
                <base-dropdown tag="li" class="nav-item" v-if="roles.indexOf('OWNER') != -1">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="ni ni-collection d-lg-none"></i>
                        <span class="nav-link-inner--text">Market Data</span>
                    </a>
                    <router-link to="/marketdata-monitor" class="dropdown-item">Live Monitor</router-link>
                    <router-link to="/marketdata-price-history" class="dropdown-item">Price History</router-link>
                </base-dropdown>
                <base-dropdown tag="li" class="nav-item" v-if="roles.indexOf('OWNER') != -1">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="ni ni-collection d-lg-none"></i>
                        <span class="nav-link-inner--text">RFQ</span>
                    </a>
                    <router-link to="/rfq-monitor" class="dropdown-item">RFQ Monitor</router-link>
                </base-dropdown>
            </ul>
            
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
                <!--
                <li class="nav-item">
                    <a class="nav-link nav-link-icon" href="https://www.instagram.com/inovaquant"
                       target="_blank" rel="noopener" data-toggle="tooltip" title="Follow us on Instagram">
                        <i class="fa fa-instagram"></i>
                        <span class="nav-link-inner--text d-lg-none">Instagram</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link nav-link-icon" href="#" target="_blank" rel="noopener"
                       data-toggle="tooltip" title="Follow us on Twitter">
                        <i class="fa fa-twitter-square"></i>
                        <span class="nav-link-inner--text d-lg-none">Twitter</span>
                    </a>
                </li>
                -->
                <li class="nav-item d-none d-lg-block ml-lg-4">
                    <router-link to="/login" class="btn btn-neutral btn-icon" v-if="!logged">
                        <span class="btn-inner--icon">
                            <i class="fa fa-user-circle mr-2"></i>
                        </span>
                        <span class="nav-link-inner--text">Entrar</span>
                    </router-link>
                    <base-button class="btn btn-neutral btn-icon" @click.prevent="logout" v-if="logged">
                        <span class="btn-inner--icon">
                            <i class="fa fa-user-circle mr-2"></i>
                        </span>
                        <span class="nav-link-inner--text">Sair</span>
                    </base-button>
                </li>
            </ul>
            <notification-listener v-if="logged"></notification-listener>
        </base-nav>
    </header>
    
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import NotificationEventListener from "@/components/NotificationEventListener";
import jwt_decode from 'jwt-decode';

export default {
    data() {
        return {
            logged: false,
            roles: [],
        }
    },
    mounted() {
        this.update_values();
        this.$root.bus.$on('update_login', event => {
            this.update_values();
        });
    },
    methods: {
        logout() {
            localStorage.removeItem('user_token');
            this.$root.bus.$emit('update_login', true);
            this.$router.push({ name: 'components' });
        },
        update_values() {
            try {
                const jwt = jwt_decode(localStorage.getItem('user_token'));
                this.logged = true;
                this.roles = jwt.roles;
            } catch {
                this.logged = false;
                this.roles = [];
            }
        },
    },
    components: {
        BaseNav,
        CloseButton,
        BaseDropdown,
        //NotificationEventListener
    }
};
</script>
<style>
</style>
