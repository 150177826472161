export default class {
    
    constructor() {
    }

    static settingsObject = {};

    static getSetting(key) {
        if ((typeof (Storage) !== 'undefined') && localStorage.getItem(key)) {
            return JSON.parse(this.b64DecodeUnicode(localStorage.getItem(key)));
        } else if (this.settingsObject[key]) {
            return JSON.parse(this.b64DecodeUnicode(this.settingsObject[key]));
        }
    }
    
    static removeSetting(key) {
        if ((typeof (Storage) !== 'undefined') && localStorage.getItem(key)) {
            localStorage.removeItem(key);
        } else if (this.settingsObject[key]) {
            this.settingsObject[key] = null;
        }
    }
    
    static setSetting(key, value) {
        if ((typeof (Storage) !== 'undefined')) {
            localStorage.setItem(key, this.b64EncodeUnicode(JSON.stringify(value)));
        } else {
            this.settingsObject[key] = this.b64EncodeUnicode(JSON.stringify(value));
        }
    }
    
    static b64DecodeUnicode(str) {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)}).join(''));
    }
    
    static b64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode(parseInt(p1, 16));
        }))
    }
}

